import { z, ZodSchema } from 'zod';

export const ImageSchema: ZodSchema = z.object({
  id: z.number().nullable(),
  filename: z.string(),
  alt: z.string(),
});

export interface IImage {
  id?: number;
  filename: string;
  alt: string;
}
