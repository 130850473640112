import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { IGetGateway, ISaveGateway, IUploadGateway } from '../../gateways';
import {
  ContentHeaderSchema,
  IContentHeader,
} from '../../../entities/content-header-entity';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';

export class ContentHeaderGateway
  implements
    IGetGateway<null, IContentHeader>,
    ISaveGateway<IContentHeader, IContentHeader>,
    IUploadGateway<FormData, IContentHeader>
{
  private clientService = inject(ClientService);

  private client = this.clientService.getClient;
  private restCallBuilder: RestCallBuilder<IContentHeader> =
    new RestCallBuilder();

  get(): Observable<IContentHeader> {
    return this.restCallBuilder
      .get('/content-header/' + this.client)
      .handleErrors()
      .validate(ContentHeaderSchema)
      .build();
  }

  save(query: IContentHeader): Observable<IContentHeader> {
    return this.restCallBuilder
      .post('/content-header/', query)
      .handleErrors()
      .validate(ContentHeaderSchema)
      .build();
  }

  upload(query: FormData): Observable<IContentHeader> {
    return this.restCallBuilder
      .post('/content-header/banner', query)
      .handleErrors()
      .build();
  }
}
