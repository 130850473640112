import { Component, effect, inject, signal, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from '../../services/config/config.service';
import { CorporateDesignService } from '../../services/api/corporateDesign/corporate-design.service';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { IFont } from '../../entities/font-entity';
import { FontsService } from '../../services/api/fonts/fonts.service';
import { ICorporateDesign } from '../../entities/coporate-design-entity';
import { SubscriptionsService } from '../../services/api/subscriptions/subscriptions.service';

@Component({
  selector: 'app-shop',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent],
  templateUrl: './shop-page.component.html',
  styles: ``,
})
export class ShopPageComponent {
  private configService = inject(ConfigService);
  private fontsService = inject(FontsService);
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );

  private apiUrl = this.configService.getApiUrl;

  corporateDesign: Signal<ICorporateDesign> = this.corporateDesignService.get();
  fonts: Signal<IFont[]> = this.fontsService.get();

  constructor() {
    effect(() => {
      this.fonts()
        .filter(font => font.name !== '')
        .forEach(font => this.loadCustomFont(font));
    });
    effect(() => {
      if (this.corporateDesign().font.name !== '')
        this.loadStandardFont(this.corporateDesign());
    });
  }

  loadCustomFont(font: IFont): void {
    const fontUrl = `${this.apiUrl}/fonts/${font.name}/font`;
    const style = document.createElement('style');
    style.innerHTML = `
    @font-face {
      font-family: '${font.name}';
      src: url('${fontUrl}') format('truetype'); /* Adjust format if needed */
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `;
    document.head.appendChild(style);
  }

  loadStandardFont(corporateDesign: ICorporateDesign): void {
    const style = document.createElement('style');
    style.innerHTML = `
       body {
      font-family: '${corporateDesign.font.name}', sans-serif;
      font-size: ${corporateDesign.fontSize}px;
    }
  `;
    document.head.appendChild(style);
  }
}
