import { z, ZodSchema } from 'zod';

export const SortGroupsSchema: ZodSchema = z.object({
  id: z.number().nullable(),
  value: z.string(),
  isoCode: z.string().min(3).max(3),
});

export interface ISortGroups {
  id?: number;
  value: string;
  isoCode: string;
}

export const WrapperSchema: ZodSchema = z.object({
  resultCount: z.number(),
  resultsPerPage: z.number(),
  page: z.number(),
  data: z.array(z.object({})),
});

export interface IWrapper<T> {
  resultCount: number;
  resultsPerPage: number;
  page: number;
  data: T[];
}
