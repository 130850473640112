import { z, ZodSchema } from 'zod';

export const FontSchema: ZodSchema = z.object({
  id: z.number().nullable(),
  filename: z.string(),
  name: z.string(),
});

export interface IFont {
  id?: number;
  filename: string;
  name: string;
}
