import { z, ZodSchema } from 'zod';
import { FontSchema, IFont } from './font-entity';
import { IImage, ImageSchema } from './image-entity';
import { IText, TextSchema } from './text-entity';

export const ContentHeaderSchema: ZodSchema = z.object({
  id: z.number().nullable(),
  client: z.string(),
  banner: ImageSchema,
  headlines: z.array(TextSchema),
  headlineFontSize: z.number(),
  headlineFont: FontSchema,
  subheadlines: z.array(TextSchema),
  subheadlineFontSize: z.number(),
  subheadlineFont: FontSchema,
});

export interface IContentHeader {
  id?: number;
  client: string;
  banner: IImage;
  headlines: IText[];
  headlineFontSize: number;
  headlineFont: IFont;
  subheadlines: IText[];
  subheadlineFontSize: number;
  subheadlineFont: IFont;
}
