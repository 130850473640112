import { inject, Injectable } from '@angular/core';
import { ZodError, ZodSchema } from 'zod';
import { ErrorHandlingService } from '../errorHandling/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  private errorHandlingService = inject(ErrorHandlingService);

  validate<T>(query: T, schema: ZodSchema<T>): T {
    try {
      if (Array.isArray(query)) {
        query.forEach((item: T) => schema.parse(item));
      } else {
        schema.parse(query);
      }
    } catch (error) {
      if (error instanceof ZodError) {
        console.log(error);
        this.errorHandlingService.handleFatal(`Zod validation failed.`, error);
      }
    }
    return query;
  }
}
