import {
  Component,
  effect,
  input,
  output,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-image-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './image-input.component.html',
  styles: ``,
})
export class ImageInputComponent {
  alt = input.required<string>();
  label = input.required<string>();
  fileChange = output<File>();
  textChange = output<string>();

  selectedImage: WritableSignal<File | undefined> = signal(undefined);
  filename = new FormControl();
  text = '';

  constructor() {
    effect(() => {
      this.text = this.alt();
    });
  }

  selectImage($event: Event) {
    const target = $event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (files && files.length > 0) {
      const file = files[0];

      const nameExtension = file.name.split('.').pop();

      const allowedTypes = ['jpg', 'jpeg', 'gif', 'png', 'tiff'];
      const allowedTypesLong = [
        'image/jpeg',
        'image/jpg',
        'image/gif',
        'image/png',
        'image/tiff',
      ];

      let checkFileType = false;
      if (nameExtension && !allowedTypes.includes(nameExtension)) {
        checkFileType = true;
      }
      if (file.type !== '' && !allowedTypesLong.includes(file.type)) {
        checkFileType = true;
      }
      if (checkFileType) {
        throw new Error(`File type ${file.type} is not allowed`);
      }

      this.selectedImage.set(file);
      this.fileChange.emit(file);
    }
  }

  updateText($event: Event) {
    const target = $event.target as HTMLInputElement;
    this.text = target.value;
    this.textChange.emit(target.value);
  }
}
