import { Observable } from 'rxjs';
import { IGetGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  ISortGroups,
  IWrapper,
  WrapperSchema,
} from '../../../entities/sort-groups-entity';
import { ISubscription } from '../../../entities/subscription-entity';
import { ConfigService } from '../../config/config.service';
import { inject } from '@angular/core';

export class SubscriptionsGateway
  implements IGetGateway<string, IWrapper<ISubscription>>
{
  private configService: ConfigService = inject(ConfigService);
  private restCallBuilder: RestCallBuilder<IWrapper<ISubscription>> =
    new RestCallBuilder();
  private apiUrl: string = this.configService.externalApi;
  get(guid: string): Observable<IWrapper<ISubscription>> {
    return this.restCallBuilder
      .getExternal(`${this.apiUrl}api/v1/subscription/subscriptions/${guid}`)
      .handleErrors()
      .validate(WrapperSchema)
      .build();
  }
}
