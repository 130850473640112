import { Component, effect, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IFont } from '../../../entities/font-entity';
import { FontsService } from '../../../services/api/fonts/fonts.service';

@Component({
  selector: 'app-font-select',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './font-select.component.html',
  styles: ``,
})
export class FontSelectComponent {
  font = input<IFont>();
  label = input.required<string>();
  fontChange = output<IFont>();

  fontsService = inject(FontsService);
  fonts = this.fontsService.get();

  selectedValueControl = new FormControl(this.font()?.id);

  constructor() {
    this.selectedValueControl.valueChanges.subscribe(newValue => {
      if (newValue) {
        this.fontChange.emit(
          this.fonts().filter(font => font.id === newValue)[0]
        );
      }
    });
    effect(() => {
      console.log(this.font());
      this.selectedValueControl.setValue(this.font()?.id);
    });
  }
}
