import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../services/config/config.service';
import { catchError, map, Observable, of } from 'rxjs';
import { ErrorHandlingService } from '../../services/errorHandling/error-handling.service';
import { ValidatorService } from '../../services/validator/validator.service';
import { ZodSchema } from 'zod';

export class RestCallBuilder<T> {
  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private errorHandlingService = inject(ErrorHandlingService);
  private validatorService = inject(ValidatorService);

  private apiUrl: string | undefined = this.configService.getApiUrl;

  private entityObservable: Observable<T>;

  constructor() {
    this.entityObservable = of({} as T);
  }

  //TODO: Workaround till cms service also under ApiGateway
  getExternal(url: string) {
    this.entityObservable = this.http.get<T>(url, {
      headers: { clientId: '842D4A5C-5242-4CC2-A853-468CACCDA9D5' },
    });
    return this;
  }

  get(url: string) {
    this.entityObservable = this.http.get<T>(this.apiUrl + url);
    return this;
  }

  post(url: string, query: T | FormData) {
    this.entityObservable = this.http.post<T>(this.apiUrl + url, query);
    return this;
  }

  delete(url: string) {
    this.entityObservable = this.http.delete<T>(this.apiUrl + url);
    return this;
  }

  validate(schema: ZodSchema<T>) {
    this.entityObservable = this.entityObservable?.pipe(
      map(entity => this.validatorService.validate(entity, schema))
    );
    return this;
  }

  handleErrors() {
    this.entityObservable = this.entityObservable?.pipe(
      catchError(err => this.errorHandlingService.handle<T>(err))
    );
    return this;
  }

  build() {
    return this.entityObservable;
  }
}
