import {
  Component,
  inject,
  OnDestroy,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateDesignService } from '../../../services/api/corporateDesign/corporate-design.service';
import { ContentHeaderComponent } from '../../../components/contentHeader/contentHeader.component';
import { ICorporateDesign } from '../../../entities/coporate-design-entity';
import { SortGroupsService } from '../../../services/api/sortGroups/sort-groups.service';
import { ISortGroups, IWrapper } from '../../../entities/sort-groups-entity';
import { SubscriptionsService } from '../../../services/api/subscriptions/subscriptions.service';
import { Subscription } from 'rxjs';
import { ISubscription } from '../../../entities/subscription-entity';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule, ContentHeaderComponent],
  templateUrl: './landing-page.component.html',
  styles: ``,
})
export class LandingPageComponent implements OnDestroy {
  private sortGroupsService = inject(SortGroupsService);
  private subscriptionService = inject(SubscriptionsService);

  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );
  private componentSubscriptions: Subscription = new Subscription();

  corporateDesign: Signal<ICorporateDesign> = this.corporateDesignService.get();
  sortGroups: WritableSignal<IWrapper<ISortGroups>> =
    this.sortGroupsService.get();
  subscriptions: WritableSignal<IWrapper<ISubscription>> =
    this.subscriptionService.subscriptions;
  areSubscriptionsLoading = this.subscriptionService.areSubscriptionsLoading;

  constructor() {
    // collect all active subscriptions
    this.componentSubscriptions.add(
      // subscribe where the value is being requested
      this.subscriptionService.$subscriptions.subscribe()
    );
  }

  testSortGroups() {
    console.log('Found sortgroups', this.sortGroups());
  }

  testSubscriptions() {
    console.log('Found subscriptions', this.subscriptions());
  }

  ngOnDestroy() {
    /* destroy subscriptions at the end of component lifecycle
     * to prevent memory leaks
     */
    this.componentSubscriptions.unsubscribe();
  }
}
