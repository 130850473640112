import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ObservableInput, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  private router = inject(Router);

  private _errors: string[] = [];

  handle<T>(err: HttpErrorResponse): ObservableInput<T> {
    if ((err.status + '').startsWith('5')) {
      this.handleFatal(err.message);
    } else {
      Sentry.captureMessage(err.message, { level: 'error' });
    }
    return of({} as T);
  }

  handleFatal(message: string, extra?: unknown) {
    if (extra) {
      Sentry.captureMessage(message, {
        level: 'fatal',
        extra: { error: extra },
      });
    } else {
      Sentry.captureMessage(message, {
        level: 'fatal',
      });
    }

    this._errors.push(message);
    this.router.navigate(['/error']);
  }

  get errors() {
    return this._errors;
  }
}
