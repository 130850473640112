import { IImage, ImageSchema } from './image-entity';
import { IText, TextSchema } from './text-entity';
import { z, ZodSchema } from 'zod';

export const HeaderSchema: ZodSchema = z.object({
  id: z.number().nullable(),
  client: z.string(),
  logo: ImageSchema,
  headlines: z.array(TextSchema),
  leftImage: ImageSchema,
  rightImage: ImageSchema,
});

export interface IHeader {
  id?: number;
  client: string;
  logo: IImage;
  headlines: IText[];
  leftImage: IImage;
  rightImage: IImage;
}
