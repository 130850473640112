import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { IWrapper } from '../../../entities/sort-groups-entity';
import { ISubscription } from '../../../entities/subscription-entity';
import { SubscriptionsGateway } from './subscriptionsGateway';
import { map, Observable, switchMap, tap } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  private subscriptionsGateway = new SubscriptionsGateway();

  public subscriptions: WritableSignal<IWrapper<ISubscription>> = signal({
    resultCount: 0,
    resultsPerPage: 0,
    page: 0,
    data: [],
  });

  public getSubscriptions: WritableSignal<string> = signal('');
  public areSubscriptionsLoading: WritableSignal<boolean> = signal(false);

  public $subscriptions: Observable<Signal<IWrapper<ISubscription>>> =
    // use a signal to trigger apiCalls
    // if multiple triggers occur in short succession only the last value will be emitted
    // if the current value is equal to the last value, no new execution will start
    toObservable(this.getSubscriptions).pipe(
      // use tap() to execute side effects e.g. indicating loading
      tap(() => this.areSubscriptionsLoading.set(true)),
      // switch to the entity gateway to make the api call
      switchMap((guid: string) => this.subscriptionsGateway.get(guid)),
      // save our value in the exposed writeable signal
      tap((subscriptions: IWrapper<ISubscription>) => {
        this.subscriptions.set(subscriptions);
      }),
      // return the signal we just set
      map(() => this.subscriptions),
      // indicate that the call and internal handling of the response are over
      tap(() => this.areSubscriptionsLoading.set(false))
    );
}
