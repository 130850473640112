import { Injectable, signal, WritableSignal } from '@angular/core';
import { ISortGroups, IWrapper } from '../../../entities/sort-groups-entity';
import { SortGroupsGateway } from './sortGroupsGateway';

@Injectable({
  providedIn: 'root',
})
export class SortGroupsService {
  private sortGroupsGateway = new SortGroupsGateway();

  private sortGroups: WritableSignal<IWrapper<ISortGroups>> = signal({
    resultCount: 0,
    resultsPerPage: 0,
    page: 0,
    data: [],
  });

  constructor() {
    this.sortGroupsGateway.get().subscribe(sortGroups => {
      this.sortGroups.set(sortGroups);
    });
  }

  public get(): WritableSignal<IWrapper<ISortGroups>> {
    return this.sortGroups;
  }
}
