import { FontSchema, IFont } from './font-entity';
import { z, ZodSchema } from 'zod';

export const CorporateDesignSchema: ZodSchema = z.object({
  id: z.number().nullable(),
  client: z.string(),
  primaryColor: z.string(),
  secondaryColor: z.string(),
  fontSize: z.number(),
  font: FontSchema,
});

export interface ICorporateDesign {
  id?: number;
  client: string;
  primaryColor: string;
  secondaryColor: string;
  fontSize: number;
  font: IFont;
}
