import { Component, inject, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FontsService } from '../../../services/api/fonts/fonts.service';
import { IFont } from '../../../entities/font-entity';
import { TextInputComponent } from '../../../components/inputs/textInput/text-input.component';

@Component({
  selector: 'app-fonts-page',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TextInputComponent],
  templateUrl: './fonts-page.component.html',
  styles: ``,
})
export class FontsPageComponent {
  fontsService = inject(FontsService);

  fonts: WritableSignal<IFont[]> = this.fontsService.get();
  selectedFile: WritableSignal<File | undefined> = signal(undefined);

  private subscription: Subscription = new Subscription();

  filename = new FormControl();

  save(): void {
    const file = this.selectedFile();
    if (file) {
      const formData = new FormData();
      formData.append('font', file, file.name);

      this.selectedFile.set(undefined);
      this.filename.reset();

      this.subscription.add(
        this.fontsService
          .updateFont(formData)
          .subscribe(cd => this.fonts.set(cd))
      );
    }
  }

  selectFile($event: Event) {
    const target = $event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (files && files.length > 0) {
      const file = files[0];

      const allowedTypes = ['font/ttf', 'font/otf', 'font/woff', 'font/woff2'];
      if (!allowedTypes.includes(file.type)) {
        throw new Error(`File type ${file.type} is not allowed`);
      }
      this.selectedFile.set(file);
    }
  }

  deleteFont(font: IFont) {
    this.subscription.add(
      this.fontsService.deleteFont(font).subscribe(cd => this.fonts.set(cd))
    );
  }
}
