import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { IGetGateway, ISaveGateway, IUploadGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  CorporateDesignSchema,
  ICorporateDesign,
} from '../../../entities/coporate-design-entity';

export class CorporateDesignGateway
  implements
    IGetGateway<null, ICorporateDesign>,
    ISaveGateway<ICorporateDesign, ICorporateDesign>,
    IUploadGateway<FormData, ICorporateDesign>
{
  private clientService = inject(ClientService);
  private client = this.clientService.getClient;
  private restCallBuilder: RestCallBuilder<ICorporateDesign> =
    new RestCallBuilder();

  get(): Observable<ICorporateDesign> {
    return this.restCallBuilder
      .get('/corporate-design/' + this.client)
      .handleErrors()
      .validate(CorporateDesignSchema)
      .build();
  }

  save(query: ICorporateDesign): Observable<ICorporateDesign> {
    return this.restCallBuilder
      .post('/corporate-design/', query)
      .handleErrors()
      .validate(CorporateDesignSchema)
      .build();
  }

  upload(query: FormData): Observable<ICorporateDesign> {
    return this.restCallBuilder
      .post('/corporate-design/font', query)
      .handleErrors()
      .build();
  }
}
