import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { IGetGateway, ISaveGateway, IUploadGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import { HeaderSchema, IHeader } from '../../../entities/header-entity';

export class HeaderGateway
  implements
    IGetGateway<null, IHeader>,
    ISaveGateway<IHeader, IHeader>,
    IUploadGateway<FormData, IHeader>
{
  private clientService = inject(ClientService);
  private client = this.clientService.getClient;
  private restCallBuilder: RestCallBuilder<IHeader> = new RestCallBuilder();

  get(): Observable<IHeader> {
    return this.restCallBuilder
      .get('/header/' + this.client)
      .handleErrors()
      .validate(HeaderSchema)
      .build();
  }

  save(query: IHeader): Observable<IHeader> {
    return this.restCallBuilder
      .post('/header/', query)
      .handleErrors()
      .validate(HeaderSchema)
      .build();
  }

  upload(query: FormData): Observable<IHeader> {
    return this.restCallBuilder
      .post('/header/upload', query)
      .handleErrors()
      .build();
  }
}
