import { Observable } from 'rxjs';
import { IGetGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  ISortGroups,
  IWrapper,
  WrapperSchema,
} from '../../../entities/sort-groups-entity';
import { ConfigService } from '../../config/config.service';
import { inject } from '@angular/core';

export class SortGroupsGateway
  implements IGetGateway<null, IWrapper<ISortGroups>>
{
  private configService: ConfigService = inject(ConfigService);
  private restCallBuilder: RestCallBuilder<IWrapper<ISortGroups>> =
    new RestCallBuilder();
  private apiUrl: string = this.configService.externalApi;
  get(): Observable<IWrapper<ISortGroups>> {
    return this.restCallBuilder
      .getExternal(
        `${this.apiUrl}api/v1/admin/sortGroups?serviceAreaId=4&includeRelationSortGroups=true`
      )
      .handleErrors()
      .validate(WrapperSchema)
      .build();
  }
}
