import {
  Component,
  inject,
  OnDestroy,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentHeaderService } from '../../../services/api/contentHeader/content-header.service';
import { TextLanguageInputComponent } from '../../../components/inputs/textLanguageInput/text-language-input.component';
import { ImageInputComponent } from '../../../components/inputs/imageInput/image-input.component';
import { NumberInputComponent } from '../../../components/inputs/numbertInput/number-input.component';
import { FontSelectComponent } from '../../../components/inputs/FontSelect/font-select.component';
import { IFont } from '../../../entities/font-entity';
import { IContentHeader } from '../../../entities/content-header-entity';

@Component({
  selector: 'app-content-header-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TextLanguageInputComponent,
    ImageInputComponent,
    NumberInputComponent,
    FontSelectComponent,
  ],
  templateUrl: './content-header-page.component.html',
  styles: ``,
})
export class ContentHeaderPageComponent implements OnDestroy {
  contentHeaderService = inject(ContentHeaderService);

  contentHeader: WritableSignal<IContentHeader> =
    this.contentHeaderService.get();
  selectedFile: WritableSignal<File | undefined> = signal(undefined);

  private subscription: Subscription = new Subscription();

  langIso = 'deu';

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save(): void {
    const newContentHeader: IContentHeader = { ...this.contentHeader() };

    if (this.selectedFile()) {
      const formData = new FormData();

      const banner = this.selectedFile();

      banner ? formData.append('banner', banner, banner.name) : null;
      formData.append('contentHeader', JSON.stringify(newContentHeader));

      this.selectedFile.set(undefined);

      this.subscription.add(
        this.contentHeaderService
          .updateBanner(formData)
          .subscribe(h => this.contentHeader.set(h))
      );
    } else {
      this.subscription.add(
        this.contentHeaderService
          .save(newContentHeader)
          .subscribe(cd => this.contentHeader.set(cd))
      );
    }
  }

  selectBanner($event: File) {
    this.selectedFile.set($event);
  }

  onBannerAltChange(value: string) {
    const newContentHeader = this.contentHeader();
    newContentHeader.banner.alt = value;
    this.contentHeader.set(newContentHeader);
  }

  onLangChange(value: string) {
    this.langIso = value;
  }

  onHeadlineChange(value: string) {
    const newContentHeader = this.contentHeader();
    if (
      newContentHeader.headlines.filter(t => t.isoCode === this.langIso)
        .length > 0
    ) {
      newContentHeader.headlines = newContentHeader.headlines.map(t => {
        if (t.isoCode === this.langIso) {
          t.value = value;
          return t;
        }
        return t;
      });
    } else {
      newContentHeader.headlines.push({ value: value, isoCode: this.langIso });
    }
    this.contentHeader.set(newContentHeader);
  }

  onSubheadlineChange(value: string) {
    const newContentHeader = this.contentHeader();
    if (
      newContentHeader.subheadlines.filter(t => t.isoCode === this.langIso)
        .length > 0
    ) {
      newContentHeader.subheadlines = newContentHeader.subheadlines.map(t => {
        if (t.isoCode === this.langIso) {
          t.value = value;
          return t;
        }
        return t;
      });
    } else {
      newContentHeader.subheadlines.push({
        value: value,
        isoCode: this.langIso,
      });
    }
    this.contentHeader.set(newContentHeader);
  }

  onHeadlineFontSizeChange(value: number) {
    const ch = this.contentHeader();
    ch.headlineFontSize = value;
    this.contentHeader.set(ch);
  }

  onSubheadlineFontSizeChange(value: number) {
    const ch = this.contentHeader();
    ch.subheadlineFontSize = value;
    this.contentHeader.set(ch);
  }

  onHeadlineFontChange(value: IFont) {
    const ch = this.contentHeader();
    ch.headlineFont = value;
    this.contentHeader.set(ch);
  }

  onSubheadlineFontChange(value: IFont) {
    const ch = this.contentHeader();
    ch.subheadlineFont = value;
    this.contentHeader.set(ch);
  }
}
