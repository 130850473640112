import { Observable } from 'rxjs';
import { IDeleteGateway, IGetGateway, IUploadGateway } from '../../gateways';
import { FontSchema, IFont } from '../../../entities/font-entity';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';

export class FontGateway
  implements
    IGetGateway<null, IFont[]>,
    IUploadGateway<FormData, IFont[]>,
    IDeleteGateway<IFont, IFont[]>
{
  private restCallBuilder: RestCallBuilder<IFont[]> = new RestCallBuilder();

  get(): Observable<IFont[]> {
    return this.restCallBuilder
      .get('/fonts/')
      .handleErrors()
      .validate(FontSchema)
      .build();
  }

  upload(query: FormData): Observable<IFont[]> {
    return this.restCallBuilder.post('/fonts/', query).handleErrors().build();
  }

  delete(query: IFont): Observable<IFont[]> {
    return this.restCallBuilder
      .delete('/fonts/' + query.name)
      .handleErrors()
      .build();
  }
}
