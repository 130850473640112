<app-content-header></app-content-header>
<p>landing-page works!</p>
@if (corporateDesign()) {
  <div class="p-3" [style.background-color]="corporateDesign().primaryColor">
    test
  </div>
}
<button (click)="testSortGroups()" type="button">Teste Sortengruppen</button>

<button
  class="bg-blue-600 text-white disabled:cursor-not-allowed disabled:bg-gray-400"
  [disabled]="areSubscriptionsLoading()"
  (click)="testSubscriptions()"
  type="button">
  Teste Abonnements
</button>
