import { Component, computed, inject, Signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CorporateDesignService } from '../../services/api/corporateDesign/corporate-design.service';
import { HeaderService } from '../../services/api/header/header.service';
import { ConfigService } from '../../services/config/config.service';
import { ClientService } from '../../services/client/client.service';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import { TemplatePipe } from '../../pipes/template/template.pipe';
import { ICorporateDesign } from '../../entities/coporate-design-entity';
import { IHeader } from '../../entities/header-entity';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, LanguagePipe, TemplatePipe],
  templateUrl: './header.component.html',
  styles: ``,
})
export class HeaderComponent {
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );
  private headerService: HeaderService = inject(HeaderService);
  private configService = inject(ConfigService);
  private clientService = inject(ClientService);

  private apiUrl = this.configService.getApiUrl;
  private client = this.clientService.getClient;

  corporateDesign: Signal<ICorporateDesign> = this.corporateDesignService.get();
  header: Signal<IHeader> = this.headerService.get();

  logoPath = computed(() => {
    return this.apiUrl + '/header/' + this.client + '/logo';
  });
  rightPath = computed(() => {
    return this.apiUrl + '/header/' + this.client + '/right';
  });
  leftPath = computed(() => {
    return this.apiUrl + '/header/' + this.client + '/left';
  });
}
