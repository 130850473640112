import { z, ZodSchema } from 'zod';

export const TextSchema: ZodSchema = z.object({
  id: z.number().nullable(),
  value: z.string(),
  isoCode: z.string().min(3).max(3),
});

export interface IText {
  id?: number;
  value: string;
  isoCode: string;
}
